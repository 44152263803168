import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import PropTypes from "prop-types";

export default function HymnIndexPage({ data }) {
  const hymns = data.allHymnJson.edges.map((edge) => {
    return {
      ID: edge.node.ID,
      title: edge.node.title,
    };
  });
  return (
    <Layout>
      <SEO
        keywords={[
          `詩歌`,
          `讚美詩歌`,
          `中文詩歌`,
          `中文讚美詩歌`,
          `繁體詩歌`,
          `繁體讚美詩歌`,
        ]}
        title="詩歌資源"
        description="詩歌資源包括大量詩歌，讓你可以在祟拜、參加小組時可以朗讀及歌唱。"
      />

      <section>
        <h1 className="text-center m-2 text-3xl font-bold">詩歌資源</h1>
        <div className="m-2">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {hymns.map((hymn) => (
              <Link
                key={hymn.ID}
                className="bg-red-600 text-white hover:bg-white hover:text-red-600 text-center p-2 m-1 text-xl"
                to={`/hymns/${hymn.ID}/`}
              >
                {hymn.title}
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

HymnIndexPage.propTypes = {
  data: PropTypes.shape({
    allHymnJson: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            ID: PropTypes.string,
            title: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const query = graphql`
  query MyQuery {
    allHymnJson {
      edges {
        node {
          ID
          title
        }
      }
    }
  }
`;
